/* eslint-disable max-len */
/* eslint-disable eqeqeq */
/* eslint-disable quote-props */
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Request } from '../interfaces/request';
import { AppServicesService } from '../services/app-services.service';
import { Users } from '../interfaces/users';
import { EditprofilComponent } from '../editprofil/editprofil.component';
import { ModalListRequestsbyfundsComponent } from '../modal-list-requestsbyfunds/modal-list-requestsbyfunds.component';
import { SecuritysettingsComponent } from '../securitysettings/securitysettings.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profil-user',
  templateUrl: './profil-user.component.html',
  styleUrls: ['./profil-user.component.scss'],
})
export class ProfilUserComponent implements OnInit {
listrequests: Request[]=[];
actualuser: Users;
imgUrl=this.appserv.imgUrl;
defaultavatar='../../assets/avatar.svg';
constructor(private route: Router, private modalctr: ModalController,public appserv: AppServicesService,
   private alertctrl: AlertController, public translate: TranslateService) { }

  ngOnInit() {
    this.actualuser=this.appserv.getactualuser();
  }

  changetranslate(langSelect: string){
    this.translate.use(langSelect);
    this.appserv.presentToast(this.translate.instant('login'),'success');
  }

  async openlistrequests(){
    const modal = await this.modalctr.create({
      component:ModalListRequestsbyfundsComponent,
      componentProps:{'listrequests':this.listrequests},
      cssClass:'modal-border-radius-20'
    });
    modal.present();
  }

async securitypage(){
  const modal = await this.modalctr.create({
    component:SecuritysettingsComponent,
    componentProps:{'usersent':this.actualuser},
    cssClass:'modal-border-radius-20'
  });
  modal.present();
  const {data,role}= await modal.onWillDismiss();
  if(role=='updated'){
    this.appserv.presentToast(this.appserv.translate.instant('your password has been successfully reset. We will disconnect to log in again'),'success');
    this.appserv.logout();
  }
}
 async closemodal()
 {
  this.modalctr.dismiss();
}

async getlistrequest(){
  this.appserv.requestsforauser(this.actualuser.id).subscribe(
    (data: any)=>{
      this.listrequests=data;
    },
    error=>{});
}

  async disconect(){
    localStorage.removeItem('actualUser');
    this.route.navigateByUrl('/login',{replaceUrl:true});
  }

  async openeditprofil(){
    const modal = await this.modalctr.create({
      component:EditprofilComponent,
      componentProps:{'agentsent':this.actualuser},
      cssClass:'modal-border-radius-20'
    });
    modal.present();

    const {data,role} = await modal.onWillDismiss();
    if(role=='edited'){
      this.actualuser.note=data.note;
      this.actualuser.user_name=data.user_name;
      this.actualuser.user_mail=data.user_mail;
      this.actualuser.user_phone=data.user_phone;
      this.actualuser.updated_at=data.updated_at;
    }
  }
}
