import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Request } from '../interfaces/request';

@Component({
  selector: 'app-modal-list-requestsbyfunds',
  templateUrl: './modal-list-requestsbyfunds.component.html',
  styleUrls: ['./modal-list-requestsbyfunds.component.scss'],
})
export class ModalListRequestsbyfundsComponent implements OnInit {
@Input() listrequests: Request[];
search: any;
  constructor(private modalctr: ModalController) { }

  ngOnInit() {
    console.log(this.listrequests);
  }

  async closemodal(){
    this.modalctr.dismiss();
  }

}
