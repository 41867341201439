/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Users } from '../interfaces/users';
import { FormBuilder } from '@angular/forms';
import { AppServicesService } from '../services/app-services.service';

@Component({
  selector: 'app-securitysettings',
  templateUrl: './securitysettings.component.html',
  styleUrls: ['./securitysettings.component.scss'],
})
export class SecuritysettingsComponent implements OnInit {
@Input() usersent: Users;
oldpassword: string;
password1: string;
password2: string;
showsaving=false;
showsecondpasswords=false;
showprogress=false;

userform=this.formbuild.group({
  id:[],
  user_name:[],
  user_mail:[],
  user_phone:[],
  user_password:[],
  user_type:[],
  created_at:[],
  status:[],
  department_id:[],
  department_name:[],
  selected:[],
  note:[],
  level:[],
  avatar:[]
});

  constructor(private modalctrl: ModalController,private formbuild: FormBuilder,public appserv: AppServicesService) { }

  ngOnInit() {
  }

  async checkoldpassword(){

    if(this.oldpassword===this.usersent.user_password){
      this.showsecondpasswords=true;
    }else{
      this.showsecondpasswords=false;
    }
  }

  async checknewpasswords(){
    if(this.password1===this.password2 && this.password1 && this.password2 && this.password1.length>0 && this.password2.length>0){
        if(this.oldpassword===this.password1){
          this.appserv.presentToast(this.appserv.translate.instant('no changes made'),'warning');
        }else{
          this.showsaving=true;
        }
    }else{
      this.showsaving=false;
    }
  }

  async syncingdata(){
    if(this.password1 && this.password2){
      this.userform.patchValue({
        id:this.usersent.id,
        user_name:this.usersent.user_name,
        user_mail:this.usersent.user_mail,
        user_phone:this.usersent.user_phone,
        user_password:this.password1,
        user_type:this.usersent.user_type,
        status:this.usersent.status,
        department_id:this.usersent.department_id,
        department_name:this.usersent.department_name,
        note:this.usersent.note,
        avatar:this.usersent.avatar
      });
    }else{
      this.appserv.presentToast(this.appserv.translate. instant('please enter the new password'),'warning');
    }
 }
  async edituser(){
    this.syncingdata();
    this.showprogress=true;
    this.appserv.edithagentapi(this.userform.value).subscribe(
      data=>{
        this.showprogress=false;
        this.appserv.presentToast(this.appserv.translate.instant('password changed successfully'),'success');
        this.appserv.presentToast(this.appserv.translate.instant('we disconnect you and ask you to log in again to keep working'),'primary');
        this.modalctrl.dismiss();
        this.appserv.logout();
      },
      error=>{
        this.showprogress=false;
        this.appserv.presentToast(this.appserv.translate.instant('unable to edit. Please check your connection'),'danger');
      }
    );
  }
  async closemodal(){
    this.modalctrl.dismiss();
  }

}
