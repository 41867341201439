/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';
import { Tubs } from '../interfaces/tubs';
import { ProfilUserComponent } from '../profil-user/profil-user.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  showtext=true;
  showprogress=false;
  listfunds: Tubs[]=[];
  navListe =this.appserv.navListe;
  cashiernav=this.appserv.cashiernav;

  constructor(public appserv: AppServicesService) {}

  ngOnInit() {
    this.getlistTubs();
  }

  getlistTubs(){
    this.appserv.myTubs(this.appserv.actualUser.id).subscribe(
      data=>{
        this.listfunds=data;
      },
      error=>{
        this.appserv.presentToast('an error occurred while loading data.','danger');
      });
  }
  menutub(fund: Tubs){}

  async profiluser(){
    const modal = await this.appserv.modalCtrl.create({
      component:ProfilUserComponent,
      cssClass:'modal-border-radius-20'
    });
    modal.present();
  }
}
