import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';
import { Comment } from '../interfaces/comment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  listcomments: Comment[]=[];
  imgUrl=this.appserv.imgUrl;
  inloaded=true;
  search: any;
  constructor(public appserv: AppServicesService) { }

  ngOnInit() {
    this.getcomments();
  }

  async getcomments(){
    this.inloaded=true;
    this.appserv.allcommentsforuser(this.appserv.actualUser.id).subscribe(
      data=>{
        this.inloaded=false;
        this.listcomments=data;
        console.log(data);
      },error=>{
        this.inloaded=false;
        this.appserv.presentToast(this.appserv.translate.instant('an error occurred while loading data'),'danger');
      });
  }
}
