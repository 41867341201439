/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable object-shorthand */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { AppServicesService } from './app-request/services/app-services.service';
import { TranslateService } from '@ngx-translate/core';
import { Users } from './app-request/interfaces/users';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  actualuser: Users={};

  constructor(private route: Router, public translate: TranslateService, public appserv: AppServicesService, private router: Router) {
    translate.addLangs(['fr','en']);
    translate.setDefaultLang('en');
  }

  ngOnInit(){
    const olduser = this.appserv.getactualuser();
    if (olduser) {
      console.log('connected');
    } else {
      this.route.navigateByUrl('/login');
    }
  }
}
