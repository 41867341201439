/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IonInput, LoadingController, ModalController } from '@ionic/angular';
import { AppServicesService } from '../app-request/services/app-services.service';
import { CreaterapiduserComponent } from '../createrapiduser/createrapiduser.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
@ViewChild('defaultinput') defaultinput!: IonInput;
  compter = 0;

  loginform = this.form.group({
    user_name: ['', Validators.required],
    user_password: ['', Validators.required]
  });

  newagent = this.form.group({
    user_name: [],
    user_mail: [],
    user_phone: [],
    user_password: [],
    user_password2: [],
    user_type: [],
    created_at: [],
    status: [],
    department_id: [],
    department_name: [],
    selected: [],
    level: []
  });
  constructor(private modal: ModalController, private loading: LoadingController, private form: FormBuilder,
    private appserv: AppServicesService, private route: Router) { }

  ngOnInit() {
    const olduser = this.appserv.getactualuser();

    if (olduser) {
      this.route.navigateByUrl('/app-request');
    }
  }

  ionViewDidEnter(){
    this.defaultinput.setFocus();
  }

  async receiveclick() {

    if (this.compter === 6) {

      const modal = await this.modal.create({
        component: CreaterapiduserComponent
      });
      return await modal.present();
      this.compter = 0;
    } else {
      this.compter += 1;
    }
  }

  loginchecker($event){
    if($event.keyCode === 13){
      this.login();
    }
  }

async login() {

    if (this.loginform.getRawValue().user_name.length>0 && this.loginform.getRawValue().user_password.length>0) {

      const load = await this.loading.create({
        mode: 'ios',
        message: this.appserv.translate.instant('Connection in progress'),
        translucent: true,
        spinner: 'circles'
      });

      (await load).present();

      this.appserv.login(this.loginform.value).subscribe(
        (data: any) => {
          load.dismiss();
          if (data) {
            if (data.id && data.status == 'enabled') {
              localStorage.setItem('actualUser', JSON.stringify(data));
              this.appserv.actualUser = data;
              this.appserv.presentToast(this.appserv.translate.instant('successful connection'), 'success');
              this.loginform.reset();
              this.route.navigateByUrl('/app-request/folder/Requisitions');
            } else if (data.id && data.status == 'desabled' && data.user_type != 'admin') {
              this.appserv.presentToast(this.appserv.translate.instant('Your account is disabled'), 'warning');
            } else if (data.user_type == 'admin') {
              localStorage.setItem('actualUser', JSON.stringify(data));
              this.appserv.actualUser = data;
              this.appserv.presentToast(this.appserv.translate.instant('successful connection'), 'success');
              this.loginform.reset();
              this.route.navigateByUrl('/app-request/folder/Requisitions');
            }
            else {
              this.appserv.presentToast(this.appserv.translate.instant('Unable to access account'), 'danger');
            }
          } else {
            this.appserv.presentToast(this.appserv.translate.instant('Account not found'), 'warning');
          }
        },
        error => {
          load.dismiss();
          this.appserv.presentToast(this.appserv.translate.instant('Unable to access account'), 'danger');
        }
      );
    }else{
      this.appserv.presentToast(this.appserv.translate.instant('Please complete all fields'),`warning`);
    }
  }

}
