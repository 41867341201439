/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, MenuController, ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Users } from '../interfaces/users';
import { Detailrequest } from '../interfaces/detailrequest';
import { HttpClient } from '@angular/common/http';
import { Moneyconversion } from '../interfaces/moneyconversion';
import { Money } from '../interfaces/money';
import { Observable, observable } from 'rxjs';
import { Departments } from '../interfaces/departments';
import { Tubs } from '../interfaces/tubs';
import { DecisionTeam } from '../interfaces/decisionteam';
import { Conversion } from '../interfaces/conversion';
import { Comment } from '../interfaces/comment';
import { Platform } from '@ionic/angular';
import { MediasFiles } from '../interfaces/mediasfiles';
import { Mouvement } from '../interfaces/mouvement';
import { Router } from '@angular/router';
import { Request } from '../interfaces/request';
import { TransfertFound } from '../interfaces/transfert';
import { TranslateService } from '@ngx-translate/core';
import { ImageopenerComponent } from '../imageopener/imageopener.component';
import { NotificationsComponent } from '../notifications/notifications.component';
import { SettingsComponent } from '../settings/settings.component';

@Injectable({
  providedIn: 'root'
})

export class AppServicesService {
  public apiUrl = 'https://requestapi.cerocepa.com/api';
  public urlimgupload = 'https://requestapi.cerocepa.com/app/upload.php';
  public imgUrl = 'https://requestapi.cerocepa.com/app/uploads/';

  // public apiUrl='http://192.168.1.79:8000/api';
  // public urlimgupload='http://192.168.1.79:8000/app/upload.php';
  // public imgUrl: string = 'http://192.168.1.79:8000/app/uploads/';
  showrightbuttomside=false;
  notificationComponent = false;
  settingComponent = false;
  isRequestModalOpen=false;
  segmentmode = 'md';
  showtext=true;
  isOpenMenuPopover=true;
  leftsizecol=2;
  rightsizecol=10;
  actualUser: Users;
  criteria='default';
  moneydefault: Money={};
  public folderFilter='achieved';
  public today= new Date();
  listconversion: Moneyconversion[] = [];
  public navListe = [
    { title: `${this.translate.instant('agents')}`, description:`${this.translate.instant('system agent management')}`, url: '/app-request/users', icon: 'person-add' },
    { title: `${this.translate.instant('departments')}`, description:`${this.translate.instant('creation and affectation')}`, url: '/app-request/departments', icon: 'folder' },
    { title: `${this.translate.instant('accounts')}`, description:`${this.translate.instant('Creation of accounting accounts')}`, url: '/app-request/accounts', icon: 'book-outline' },
    { title: `${this.translate.instant('decision team')}`, description:`${this.translate.instant('assigning agents to the decision-making team')}`, url: '/app-request/decisionteam', icon: 'people' },
    { title: `${this.translate.instant('budget')}`, description:`${this.translate.instant('make your budget')}`, url: '/app-request/budget', icon: 'list-outline' },
    { title: `${this.translate.instant('report')}`, description:`${this.translate.instant('report your activity is with the previous budget')}`, url: '/app-request/budget/rapport', icon: 'calendar-number-outline' }
  ];

  public cashiernav=[
    { title: `${this.translate.instant('moneys')}`, description:`${this.translate.instant('creation of moneys')}`, url: '/app-request/money', icon: 'cash-outline' },
    { title: `${this.translate.instant('conversions')}`, description:`${this.translate.instant('moneys conversions')}`, url: '/app-request/conversions', icon: 'infinite-outline' },
    { title: `${this.translate.instant('funds')}`, description:`${this.translate.instant('Creation and configuration of funds')}`, url: '/app-request/tubs', icon: 'wallet' },
    { title: `${this.translate.instant('money transfers')}`, description:`${this.translate.instant('transfer easily between your funds')}`, url: '/app-request/transferts', icon: 'sync' },
  ];

constructor(public route: Router, public platform: Platform, public menu: MenuController,
    public http: HttpClient, public modalCtrl: ModalController, public toastCtrl: ToastController, public loading: LoadingController, public alertCtrl: AlertController, public translate: TranslateService,
    public actionSheet: ActionSheetController) { }

  gotorightside(action: string){
    this.settingComponent = false;
    this.notificationComponent =false;
    const browserWidth=window.innerWidth;
    const pageWidth=document.documentElement.scrollWidth;

    if (this.showrightbuttomside===false) {
        this.showrightbuttomside=true;
      if (action === 'notification') {
        this.notificationComponent = !this.notificationComponent;
      }else if(action === 'setting'){
        this.settingComponent = !this.settingComponent;
      }
    }else{
      this.showrightbuttomside=false;
    }
  }

  async modalnotifications(){
    const modal = await this.modalCtrl.create({
      component:NotificationsComponent,
      breakpoints:[0.25,0.50,0.75,1],
      initialBreakpoint:0.50,
      cssClass:'modal-border-radius-20'
    });
    modal.present();
  }

  async modalsettings(){
    const modal = await this.modalCtrl.create({
      component:SettingsComponent,
      breakpoints:[0.25,0.50,0.75,1],
      initialBreakpoint:0.50
    });
    modal.present();
  }

  hideandshowmenu(){
    this.isOpenMenuPopover=!this.isOpenMenuPopover;
    if (this.isOpenMenuPopover) {
      this.rightsizecol=10;
    }else{
      this.rightsizecol=12;
    }
  }

  changetranslate(langSelect: string){
    this.translate.use(langSelect);
    this.presentToast(this.translate.instant('language changed to '+langSelect),'success');
  }
  getdefaultmoney() {
     return null;
  }
  getactualuser() {
    const records = localStorage.getItem('actualUser');
    if (records !== null) {
      this.actualUser = JSON.parse(records);
    }
    return this.actualUser;
  }


  async openmenu() {
    this.menu.enable(true, 'main-content');
    this.menu.open('main-content');
  }

  async closemenu() {
    this.menu.enable(true, 'main-content');
    this.menu.close('main-content');
  }

  getplatform() {
    return this.platform;
  }

  getapi() {

  }

  // changelanguage(){

  // }

  logout() {
    localStorage.removeItem('actualUser');
    this.route.navigateByUrl('/login',{replaceUrl:true});
    this.route.navigateByUrl('/login',{replaceUrl:true});
  }

  setapi() {

  }
  async presentToast(msg: string, colorsent: string = '') {
    const toast = await this.toastCtrl.create({
      mode: 'ios',
      position: 'bottom',
      color: `${colorsent}`,
      buttons: [
        { text: this.translate.instant('cancel') }
      ],
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  async closemodal() {
    this.modalCtrl.dismiss();
  }

  login(user: Users): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/users/login', user);
  }

  gotoMenu(url: string,filter: string){
    this.route.navigateByUrl(url,{replaceUrl:true});
    this.folderFilter=filter;
  }
  async openfile(file: any,type: any){
    const modal = await this.modalCtrl.create({
      component:ImageopenerComponent,
      componentProps:{'fileSent':file,'typeSent':type},
      cssClass: 'modal-500-width modal-border-radius-20'
    });
    modal.present();
  }
  /**
   * API ROUTES
   */

  //DEPARTMENTS
  getlistdepartmentsapi(): Observable<Departments[]> {
    return this.http.get<Departments[]>(this.apiUrl + '/departments');
  }

  newdepartmentapi(department: Departments): Observable<Departments> {
    return this.http.post<Departments>(this.apiUrl + '/departments', department);
  }

  editdepartmentapi(department: Departments): Observable<Departments> {
    return this.http.put<Departments>(this.apiUrl + '/departments/update/' + department.id, department);
  }

  deletedepartmentapi(idDepart: number) {
    return this.http.delete(this.apiUrl + '/department/delete/' + idDepart);
  }

  getdepartmentbyid(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/departments/findbyid/' + id);
  }

  getdepartmentuser(request: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/departments/findbyuser', request);
  }

  getaffectedusers(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/departments/getusers/' + id);
  }

  getsubdepartments(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/departments/subdeparts/' + id);
  }
  newaffectation(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/affectation_users', data);
  }

  deleteaffectation(data: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/affectation/delete/' + data);
  }

  //Accounts requests
  attachaccount(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/request/attach-account',data);
  }

  desattachaccount(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/accountrequests/desaffectaccount',data);
  }
  //USERS
  getlistagentsapi(): Observable<Users[]> {
    return this.http.get<Users[]>(this.apiUrl + '/users');
  }

  getUsersUser(request: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/users/findbyuser', request);
  }

  getoneagentapi(id: any): Observable<any> {
    return this.http.get<Users>(this.apiUrl + '/getsingleagent', id);
  }

  getuseraccess(id: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/getaccess/' + id);
  }

  newagentapi(user: Users): Observable<Users> {
    return this.http.post<Users>(this.apiUrl + '/users', user);
  }

  deletinglistdiffusion(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/users/listdiffusion/delete', data);
  }

  creatinglistdiffusion(data: any): Observable<Users[]> {
    return this.http.post<Users[]>(this.apiUrl + '/users/creatinglistdiffusion', data);
  }

  gettinglistdiffusion(user: any): Observable<Users[]> {
    return this.http.get<Users[]>(this.apiUrl + '/users/listdiffusion/'+user);
  }

  edithagentapi(user: any): Observable<Users> {
    return this.http.patch<Users>(this.apiUrl + '/users/update/' + user.id, user);
  }

  deleteagentapi(id: number) {
    return this.http.delete(this.apiUrl + '/users/delete/' + id);
  }

  //AFFECTATION USERS
  getlistaffectionsapi(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/affectation_users');
  }

  newaffectationapi(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/affectation_users', data);
  }

  desaffectapi(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/affectation_users/desaffectation',data);
  }

  updateaffectationapi(data: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + '/affectation/update', data);
  }
  //MONNAIES
  getlistmonnaiesapi(): Observable<Money[]> {
    return this.http.get<Money[]>(this.apiUrl + '/money');
  }

  newmoneyapi(money: Money): Observable<Money> {
    return this.http.post<Money>(this.apiUrl + '/money', money);
  }

  defaultmoney(enterpriseId): Observable<Money>{
    return this.http.get<Money>(this.apiUrl +'/money/default/'+enterpriseId);
  }
  editmoneyapi(money: any): Observable<Money> {
    return this.http.patch<Money>(this.apiUrl + '/money/update/' + money.id, money);
  }

  deletemoneyapi(money: any) {
    return this.http.delete(this.apiUrl + '/money/delete/' + money.id);
  }

  //MONEY CONVERSION

  getlistconversionapi(): Observable<Conversion[]> {
    return this.http.get<Conversion[]>(this.apiUrl + '/money_conversion');
  }

  newconversionapi(conversion: any): Observable<Conversion> {
    return this.http.post<Conversion>(this.apiUrl + '/money_conversion', conversion);
  }

  editconversionapi(conversion: any): Observable<Conversion> {
    return this.http.patch<Conversion>(this.apiUrl + '/money_conversion/update/' + conversion.id, conversion);
  }

  deleteconversionapi(conversion: any) {
    return this.http.delete(this.apiUrl + '/money_conversion/delete/' + conversion.id, conversion);
  }

  //MOUVEMENTS
  getlistmouvementsbyfund(id: number): Observable<Mouvement[]> {
    return this.http.get<Mouvement[]>(this.apiUrl + '/request_history/byfund/' + id);
  }
  newoperation(mouv: Mouvement): Observable<Mouvement> {
    return this.http.post<Mouvement>(this.apiUrl + '/request_history', mouv);
  }

  deleterequesthistory(data: any): Observable<any>{
    return this.http.delete<any>(this.apiUrl + '/request_history/delete/'+data.id, data);
  }
  //CAISSES
  getlistTubsapi(): Observable<Tubs[]> {
    return this.http.get<Tubs[]>(this.apiUrl + '/funds');
  }

  myTubs(user): Observable<Tubs[]> {
    return this.http.get<Tubs[]>(this.apiUrl + '/funds/mines/'+user);
  }

  newtubapi(tub: Tubs): Observable<Tubs> {
    return this.http.post<Tubs>(this.apiUrl + '/funds', tub);
  }

  editTubapi(tub: Tubs): Observable<Tubs> {
    return this.http.patch<Tubs>(this.apiUrl + '/funds/update/' + tub.id, tub);
  }
  resetTub(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/funds/reset',data);
  }
  deleteTubapi(tubid) {
    return this.http.delete(this.apiUrl + '/funds/delete/' + tubid);
  }

  //EQUIPE DECISIONNELLE
  getlistdecisionteamapi(): Observable<DecisionTeam[]> {
    return this.http.get<DecisionTeam[]>(this.apiUrl + '/decision_team');
  }

  newdecisionteamapi(team: DecisionTeam): Observable<DecisionTeam> {
    return this.http.post<DecisionTeam>(this.apiUrl + '/decision_team', team);
  }

  edithdecisionteamapi(team: DecisionTeam): Observable<DecisionTeam> {
    return this.http.patch<DecisionTeam>(this.apiUrl + '/decision_team/update/' + team.id, team);
  }

  deletedecisionapi(team: any) {
    return this.http.delete(this.apiUrl + '/decision_team/delete/' + team.id);
  }

  //NUMBER DECISION TEAM
  getnumberdecisionteamapi(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/nbrdecisionteam_validation');
  }

  setnbrdecisionapi(nombre: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/nbrdecisionteam_validation', nombre);
  }

  //REQUEST ROUTES

  requestsforauser(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/requests/user2/' + userid);
  }

  allcommentsforuser(userid: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.apiUrl + '/requests/user/comments/' + userid);
  }

  singlerequests(requestid: any): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/requests/single/' + requestid);
  }


  requestsdoneby(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/requests/user/' + userid);
  }

  requestsfordepartment(departid: number) {
    return this.http.get<Request[]>(this.apiUrl + '/requests/bydepart/' + departid);
  }

  getallrequestapi(): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/request');
  }

  getrequestlistapi(): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/request');
  }

  getfilesfordepartment(departid: number): Observable<MediasFiles[]> {
    return this.http.get<MediasFiles[]>(this.apiUrl + '/requests/files/depart/' + departid);
  }

  newrequestapi(request: any): Observable<Request> {
    return this.http.post<Request>(this.apiUrl + '/request', request);
  }

  requestvalidation(request: any, userid: number): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/request/validation/'+userid,request);
  }

  deleterequest(id: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/request_delete/${id}`);
  }

  deletemultiplerequests(requests: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/request/deletemultiplerequests`,requests);
  }

  getstatisticvariables(requests: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/request/statistic`,requests);
  }

  revivingmultiple(requests: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/request/revivingmultiple`,requests);
  }

  archivemultiplerequests(requests: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + `/request/archivemultiplerequests`,requests);
  }

  editrequest(request: any): Observable<Request> {
    return this.http.put<Request>(this.apiUrl + `/request_update/` + request.id, request);
  }

  tovalidate(request: any): Observable<any>{
    return this.http.get<any>(this.apiUrl +'/requests/tovalidate/'+request);
  }

  tovalidatebychiefdepart(object: any): Observable<Request[]> {
    return this.http.post<Request[]>(this.apiUrl + '/tovalidatebychiefdepart/',object);
  }

  tovalidatebydecisionteam(object: any): Observable<Request[]> {
    return this.http.post<Request[]>(this.apiUrl + '/tovalidatebydecisionteam/',object);
  }

  validatedbychiefdepart(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/validatedbychiefdepart/' + userid);
  }

  unvalidatedbychiefdepart(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/unvalidatedbychiefdepart/' + userid);
  }

  validatedbydecisionteam(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/validatedbydecisionteam/' + userid);
  }

  validatedbydecisionteamall(): Observable<any[]> {
    return this.http.get<Request[]>(this.apiUrl + '/requests/decisionteamall');
  }

  unvalidatedbydecisionteam(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/unvalidatedbydecisionteam/' + userid);
  }

  tobserved(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/tobeserved/' + userid);
  }

  tobservedall(): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/tobeservedall');
  }

  alreadyserved(userid: number): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/alreadyserved/' + userid);
  }

  alreadyservedall(): Observable<Request[]> {
    return this.http.get<Request[]>(this.apiUrl + '/alreadyservedall');
  }

  requestservedbytub(tubid: number): Observable<any[]> {
    return this.http.get<any[]>(this.apiUrl + '/requests/requestservedbytub/' + tubid);
  }

  filteredrequest(data: any): Observable<any>{
    return this.http.post<any>(this.apiUrl + '/requests/filter',data);
  }

  chefdepartValidator(data: any): Observable<any>{
    return this.http.get<any>(this.apiUrl + '/requests/chefdepartvalidator/'+data);
  }

  decisionteamValidators(data: any): Observable<any>{
    return this.http.get<any>(this.apiUrl + '/requests/decisionteamValidators/'+data);
  }

  cashierdisburser(data: any): Observable<any>{
    return this.http.get<any>(this.apiUrl + '/requests/cashierdisburser/'+data);
  }

  getsentTolist(request: any): Observable<Users[]>{
    return this.http.post<Users[]>(this.apiUrl + '/requests/sentolist',request);
  }

  shareRequestWith(request: any): Observable<Users[]>{
    return this.http.post<Users[]>(this.apiUrl + '/requests/sharerequestwith',request);
  }

  deletesharetWith(request: any): Observable<Users[]>{
    return this.http.post<Users[]>(this.apiUrl + '/requests/deletesharewith',request);
  }

  getinboxsubdata(data: any): Observable<Request[]>{
    return this.http.post<Request[]>(this.apiUrl + '/requests/getinboxsubdata',data);
  }

  attachMulitpleRequestsToAccount(data: any): Observable<Request[]>{
    return this.http.post<Request[]>(this.apiUrl + '/requests/attachMulitpleRequestsToAccount',data);
  }

  //DETAILS REQUEST

  sendrequestdetails(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/details_request', data);
  }

  editdetailrequest(detail: any): Observable<any> {
    return this.http.put<any>(this.apiUrl + '/details_request/update/'+detail.id, detail);
  }

  deletedetailrequest(detail: any): Observable<any> {
    return this.http.delete<any>(this.apiUrl + '/details_request/delete/'+detail);
  }

  getrequestdetails(requestid: number): Observable<Detailrequest[]> {
    return this.http.get<Detailrequest[]>(this.apiUrl + '/request_details/' + requestid);
  }

  //VALIDATIONS CHEF DE DEPARTEMENT
  newvalidationchief(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/decision_chiefdepartments', data);
  }

  singledecisionchef(requestid: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/decision_chief/single/' + requestid);
  }
  //VALIDATIONS DECISION TEAM
  newvalidationdecisionteam(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/decision_decisionteam', data);
  }
  //COMMENTS API
  getlistcomments(requestid: number): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.apiUrl + '/getByid_request/' + requestid);
  }

  newcomment(comment: any): Observable<Comment> {
    return this.http.post<Comment>(this.apiUrl + '/comments', comment);
  }

  saverequestingreferences(data: any) {
    return this.http.post<any>(this.apiUrl + '/request_references', data);
  }

  deletereference(data: any): Observable<any>{
    return this.http.delete<any>(this.apiUrl + '/request_references/delete/'+data.id);
  }

  //DECAISSEMENTS
  getlistpayments(requestid: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/request_served/' + requestid);
  }

  newpayment(payment: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/request_served', payment);
  }

  updatepayment(data: any): Observable<any>{
    return this.http.put<any>(this.apiUrl + '/request_served/update/'+data.id,data);
  }

  deletepayment(data: any): Observable<any>{
    return this.http.delete<any>(this.apiUrl + '/request_served/delete/'+data.id,data);
  }

  getpaymenthistory(requestid: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/request_served_by_id_Request/' + requestid);
  }

  //REFERENCES FILES REQUEST ROUTES
  getrequestfiles(requestid: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/request_files/single/' + requestid);
  }

  sendrequestfiles(data: any): Observable<any> {
    return this.http.post<any>(this.apiUrl + '/request_files', data);
  }

  deleteFileRequest(data: any): Observable<any>{
    return this.http.delete<any>(this.apiUrl + '/request_files/delete/'+data.id, data);
  }

  getrequestreferences(requestid: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/request_references/single/' + requestid);
  }

  //Transfert Founds
  newtransfert(data: any): Observable<TransfertFound>{
    return this.http.post<TransfertFound>(this.apiUrl + '/transfertfound',data);
  }

  deletetransfer(data: any): Observable<any>{
    return this.http.delete<any>(this.apiUrl + '/transfertfound/delete/'+data.id, data);
  }

  getTransfertForaTub(data: any): Observable<TransfertFound[]>{
    return this.http.post<TransfertFound[]>(this.apiUrl + '/transfertfound/tubsender',data);
  }

  getalltransfert(data: any): Observable<TransfertFound[]>{
    return this.http.get<TransfertFound[]>(this.apiUrl + '/transfertfound/enterprise/'+data);
  }

}
