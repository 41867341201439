/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { AppServicesService } from '../app-request/services/app-services.service';

@Component({
  selector: 'app-createrapiduser',
  templateUrl: './createrapiduser.component.html',
  styleUrls: ['./createrapiduser.component.scss'],
})
export class CreaterapiduserComponent implements OnInit {

  newagent = this.form.group({
    user_name: [],
    user_mail: [],
    user_phone: [],
    user_password: [],
    user_password2: [],
    user_type: [],
    created_at: [],
    status: [],
    department_id: [],
    department_name: [],
    selected: [],
    level: []
  });

  constructor(private route: Router,
     private loading: LoadingController,
     private appserv: AppServicesService,
     private form: FormBuilder,
     private modal: ModalController)
     { }

  ngOnInit() { }

  async closemodal() {
    this.modal.dismiss();
  }

  async addagent() {
    const load = await this.loading.create({
      translucent: true,
      mode: 'ios',
      message: 'Enregistrement en cours...',
      spinner: 'circles'
    });
    (await load).present();

    this.newagent.patchValue({
      status: 'desabled'
    });

    this.appserv.newagentapi(this.newagent.value).subscribe(
      data => {
        load.dismiss();

        const records = localStorage.getItem('actualUser');
        if (records !== null) {
          localStorage.removeItem('actualUser');
          localStorage.setItem('actualUser', JSON.stringify(data[0]));
        } else {
          localStorage.setItem('actualUser', JSON.stringify(data[0]));
        }

        this.appserv.presentToast(`Agent ${data[0].user_name} ajouté avec succès!`, 'success');
        this.newagent.reset();
        this.route.navigateByUrl('/folder/requests');
      },
      error => {
        load.dismiss();
        this.appserv.presentToast('Erreur d\'enregistrement. Une erreur est survenue sur le serveur.', 'warning');
      }
    );
  }
}
