/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/type-annotation-spacing */
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HashLocationStrategy,LocationStrategy } from '@angular/common';
/** material importing */
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule} from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';

import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { SwiperModule } from 'swiper/angular';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { LoginComponent } from './login/login.component';
import { CreaterapiduserComponent } from './createrapiduser/createrapiduser.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpTranslateLoaderFactory(http:HttpClient){
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations:
    [
      AppComponent,
      LoginComponent,
      CreaterapiduserComponent,
    ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatListModule,
    Ng2SearchPipeModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatMenuModule,
    MatChipsModule,
    SwiperModule,
    MatTooltipModule,
    MatListModule,
    TranslateModule.forRoot({
      loader:{
        provide:TranslateLoader,
        useFactory:httpTranslateLoaderFactory,
        deps:[HttpClient]
      }
    })
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },{provide:LocationStrategy,useClass:HashLocationStrategy}, FileTransfer, Camera, AndroidPermissions, EmailComposer],
  bootstrap: [AppComponent],
})
export class AppModule { }
