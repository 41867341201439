/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit,Input } from '@angular/core';
import { ModalController, AlertController, ActionSheetController, Platform } from '@ionic/angular';
import { Users } from '../interfaces/users';
import { AppServicesService } from '../services/app-services.service';
import { FormBuilder } from '@angular/forms';
import { Camera, CameraResultType,CameraSource,Photo } from '@capacitor/camera';
import { Filesystem} from '@capacitor/filesystem';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-editprofil',
  templateUrl: './editprofil.component.html',
  styleUrls: ['./editprofil.component.scss'],
})
export class EditprofilComponent implements OnInit {
@Input() agentsent: Users;
draftagent: Users;
defaultavatar='../../assets/avatar.svg';
iseditingmodeactivated=false;
showprogress=false;
imgUrl=this.appserv.imgUrl;
sendingagent=this.formbuild.group({
  id:[],
  user_name:[],
  user_mail:[],
  user_phone:[],
  user_password:[],
  user_password2:[],
  user_type:[],
  created_at:[],
  status:[],
  department_id:[],
  department_name:[],
  selected:[],
  note:[],
  level:[],
  avatar:[],
});
  constructor(private http: HttpClient, private platform: Platform,private formbuild: FormBuilder,private modalctr: ModalController,
    private appserv: AppServicesService,private alertctrl: AlertController,private actionsheet: ActionSheetController) { }

  ngOnInit() {
    this.syncingdata();
  }
  async closemodal()
  {
   this.modalctr.dismiss();
  }

 async validatemodification(){
  this.showprogress=true;
  this.appserv.edithagentapi(this.sendingagent.value).subscribe(
    data=>{
      this.showprogress=false;
      this.appserv.presentToast(this.appserv.translate.instant('agent information successfully modified'),'success');
      if(this.appserv.actualUser.id===this.agentsent.id){
        localStorage.setItem('actualUser',JSON.stringify(data));
      }
      this.modalctr.dismiss(data,'edited');
    },
    error=>{
      this.showprogress=false;
      this.appserv.presentToast(this.appserv.translate.instant('unable to edit. Please check your connection'),'danger');
    }
  );
 }

 async editavatar(){
  const sheet = await this.actionsheet.create({
    mode:'ios',
    translucent:true,
    buttons:[
      {text:this.appserv.translate.instant('cancel'),role:'cancel'},
      {text:this.appserv.translate.instant('delete photo'),handler:async ()=>{this.deleteimage();}},
      {text:this.appserv.translate.instant('take a photo'),handler:async ()=>{this.takephoto();}},
      {text:this.appserv.translate.instant('choose a photo'),handler:async ()=> {this.selectImage();}}
    ]
  });
  sheet.present();
 }

 async deleteimage(){
  this.agentsent.avatar='';
 };

 async selectImage(){
  const image = await Camera.getPhoto({
    quality:90,
    allowEditing:true,
    resultType: CameraResultType.Uri,
    source:CameraSource.Photos
  });

  if(image){
    this.saveImage(image);
  }
}

async takephoto(){
  const image = await Camera.getPhoto({
    quality:90,
    allowEditing:true,
    resultType: CameraResultType.Uri,
    source:CameraSource.Camera
  });

  if(image){
    this.saveImage(image);
  }
}

async saveImage(photo: Photo){
  const base64Data = await this.readAsBase64(photo);
  const filesize = await this.gettingsize(photo);
  const fileName = new Date().getTime() + '.jpeg';
  const newimg={size:filesize,filename:fileName,name:fileName,path:photo.webPath,data:base64Data,extension:'jpeg'};
  this.startUpload(newimg);
}

async gettingsize(photo: Photo){
  const response = await fetch(photo.webPath);
  const blob = await (await response.blob()).size;
  return await blob;
}

async readAsBase64(photo: Photo){
    if(this.platform.is('hybrid')){
      const file = await Filesystem.readFile({
        path:photo.path
      });
      return file.data;
  }
  else{
    const response = await fetch(photo.webPath);
    const blob = await response.blob();
    return await this.convertBlobToBase64(blob) as string;
  }
}

convertBlobToBase64 = (blob: Blob) => new Promise((resolve,reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.readAsDataURL(blob);
});

async startUpload(file: any){
  const response = await fetch(file.data);
  const blob=await response.blob();
  const formData = new FormData();
  formData.append('file',blob,file.name);
  this.uploadData(formData,file);
}

async uploadData(formData: FormData,file: any){
const url =`${this.appserv.urlimgupload}`;
this.http.post(url,formData).subscribe(
  (data: any)=>{
    this.sendingagent.patchValue({
      avatar:file.name
    });
    if(data.success==true){
      this.agentsent.avatar=file.name;
      this.iseditingmodeactivated=true;
      this.validatemodification();
    }
  },
  error=>{
  }
);
}

 async syncingdata(){
    this.sendingagent.patchValue({
      id:this.agentsent.id,
      user_name:this.agentsent.user_name,
      user_mail:this.agentsent.user_mail,
      user_phone:this.agentsent.user_phone,
      user_password:this.agentsent.user_password,
      user_password2:this.agentsent.user_password,
      user_type:this.agentsent.user_type,
      status:this.agentsent.status,
      department_id:this.agentsent.department_id,
      department_name:this.agentsent.department_name,
      note:this.agentsent.note,
      avatar:this.agentsent.avatar
    });
 }

 async canceledition(){
  this.iseditingmodeactivated=false;
  this.syncingdata();
 }
}
