import { Component, OnInit, Input } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';

@Component({
  selector: 'app-imageopener',
  templateUrl: './imageopener.component.html',
  styleUrls: ['./imageopener.component.scss'],
})
export class ImageopenerComponent implements OnInit {
@Input() fileSent: any;
@Input() typeSent: any;
  constructor(public appserv: AppServicesService) { }

  ngOnInit() {
    console.log(this.fileSent);
    console.log(this.typeSent);
  }

}
